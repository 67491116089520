import { Loader } from 'components/Loader';
import { routeId } from '@agtuary/common/helpers/encode/base62';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useRouter } from 'next/router';
import { ReactElement, useEffect } from 'react';

function RootIndexPage() {
  const router = useRouter();
  const { currentTeam } = useCurrentUser();

  useEffect(() => {
    if (currentTeam?.id) {
      const queryParams = router.query;

      router.replace({
        pathname: '/team/[teamId]/properties',
        query: {
          ...queryParams,
          teamId: routeId(currentTeam.id),
        },
      });
    }
  }, [router, currentTeam?.id]);

  return <Loader />; // Loads, while waiting for next page
}

RootIndexPage.getLayout = (page: ReactElement) => page;

export default RootIndexPage;
